exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-charges-index-jsx": () => import("./../../../src/pages/products/charges/index.jsx" /* webpackChunkName: "component---src-pages-products-charges-index-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-payments-index-jsx": () => import("./../../../src/pages/products/payments/index.jsx" /* webpackChunkName: "component---src-pages-products-payments-index-jsx" */),
  "component---src-pages-products-validations-index-jsx": () => import("./../../../src/pages/products/validations/index.jsx" /* webpackChunkName: "component---src-pages-products-validations-index-jsx" */)
}

